export const formatFirebaseDate = (firebaseDate: any, delimiter = '.'): string => {
    if (firebaseDate) {
        const date = new Date(firebaseDate._seconds * 1000)
        const day = date.getDate().toString().padStart(2, '0')
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const year = date.getFullYear().toString().slice(2)

        if (delimiter === '.') {
            return `${day}${delimiter}${month}${delimiter}${year}`

        }

        return `20${year}${delimiter}${month}${delimiter}${day}`
    }

    return ''
}

export const getDateFromFirebaseTimestamp = (firebaseDate: any): Date => {
    return new Date(firebaseDate._seconds * 1000)
}

import { Diet } from '@constants/diet.enum'
import { TeamSize } from '@constants/teamSize.enum'

export interface DinnerOrderValidateResponse {
  order: DinnerOrder,
  errors: DinnerOrderValidateError[],
}

export interface DinnerOrderValidateError {
  type: string,
  message: string,
}

export interface DinnerOrder {
  eventId: string,
  language?: string,
  subscribeToNewsletter?: boolean,
  referralSource: ReferralSource | null,
  team: DinnerTeam,
  address: DinnerAddress
  tickets: {
    requiredTicket: DinnerTicket
    optionalTickets: DinnerTicket
  }
  payment: {
    totalAmount: number
    totalQuantity: number
    discount: DinnerDiscount
  }
}

export interface DinnerTeam {
  firstPerson: DinnerPerson;
  secondPerson: DinnerPerson;
  teamType: TeamSize;
}

export interface DinnerPerson {
  firstName: string,
  lastName: string,
  age: number | null,
  mail: string,
  phone: string,
  dietType: Diet | null,
  allergicInformation: string,
  otherInformation: string
}

export interface DinnerAddress {
  city: string;
  houseNumber: string;
  note: string;
  postcode: string;
  street: string;
  nameOnBell: string;
  geolocation: Geolocation | null;
}


export interface DinnerTicket {
  price: number;
  quantity: number;
  type: TicketType;
}

export interface DinnerDiscount {
  code: string | null;
  discountEffect?: DiscountEffect;
}

export interface DiscountEffect {
  type: DiscountType;
  amount: number;
  description: string;
}

export enum TicketType {
  REQUIRED = 'REQUIRED',
  OPTIONAL = 'OPTIONAL',
}

export enum DiscountType {
  PERCENTAGE = 'PERCENTAGE',
  ABSOLUTE = 'ABSOLUTE',
  GIFT = 'GIFT',
}

export enum ReferralSource {
    FACEBOOK = 'FACEBOOK',
    INSTAGRAM = 'INSTAGRAM',
    FRIEND = 'FRIEND',
    PRINT = 'PRINT',
    MAGAZINE = 'MAGAZINE',
    NEWSLETTER = 'NEWSLETTER',
    ONLINE = 'ONLINE',
    OTHER = 'OTHER',
}

export const createDefaultDinnerOrder = (): DinnerOrder => {
    return {
        eventId: '',
        subscribeToNewsletter: false,
        referralSource: null,
        team: {
            firstPerson: {
                firstName: '',
                lastName: '',
                age: null,
                mail: '',
                phone: '',
                dietType: null,
                allergicInformation: '',
                otherInformation: ''
            },
            secondPerson: {
                firstName: '',
                lastName: '',
                age: null,
                mail: '',
                phone: '',
                dietType: null,
                allergicInformation: '',
                otherInformation: ''
            },
            teamType: TeamSize.TEAM
        },
        address: {
            city: '',
            houseNumber: '',
            note: '',
            postcode: '',
            street: '',
            geolocation: null,
            nameOnBell: ''
        },
        tickets: {
            requiredTicket: {
                price: 0,
                quantity: 0,
                type: TicketType.REQUIRED
            }, optionalTickets: {
                price: 0,
                quantity: 0,
                type: TicketType.OPTIONAL
            }
        }, payment: {
            totalAmount: 0,
            totalQuantity: 0,
            discount: {
                code: null
            }
        }
    }
}

export const createDefaultDinnerPerson = (): DinnerPerson => {
    return {
        firstName: '',
        lastName: '',
        age: null,
        mail: '',
        phone: '',
        dietType: null,
        allergicInformation: '',
        otherInformation: ''
    }
}

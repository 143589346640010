import { AxiosPromise } from 'axios'

import { HoppingEvent } from '@models/dto/hoppingEvent.model'
import { Ticket } from '@models/dto/ticket.model'

import { dinnerApi } from '@boot/axios'

export const getCurrentEventApi = async (): AxiosPromise<HoppingEvent> => {
    const event = await dinnerApi.get('/store/events/current')

    return event
}

export const getUpcomingEventsApi = async (): AxiosPromise<HoppingEvent[]> => {
    return await dinnerApi.get('/store/events/upcoming')
}

export const getCurrentTicketsApi = async (eventId: string): AxiosPromise<Ticket[]> => {
    const tickets = await dinnerApi.get(`/store/events/${eventId}/tickets`)

    return tickets
}
